import s from './NotFound.module.css'

function NotFound() {
    return (
        <div className={s.notFound}>
            <h2>Not Found...</h2>
        </div>
        
    )
}

export default NotFound